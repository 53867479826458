import React, { PureComponent } from "react"
import ReactDOM from "react-dom"
import { withStyles } from "@material-ui/core/styles"

import Theme from "../theme"

const styles = {
  overlay: {
    position: "fixed",
    zIndex: "999999999999999",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0,0,0,0.9)"
  },
  closeButton: {
    position: "absolute",
    top: "20px",
    right: "20px",
    width: "56px",
    height: "56px",
    borderRadius: "100px",
    border: "none",
    fontWeight: "bold",
    color: Theme.palette.secondary.contrastText,
    background: Theme.palette.secondary.main,
    cursor: "pointer",
    zIndex: "20",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}

interface ModalProps {
  classes?: {
    overlay: string,
    closeButton: string
  },
  handleClose: () => void
}

interface ModalState {
  isLoading: boolean,
  hasError: boolean
}

class Modal extends PureComponent<ModalProps, ModalState> {
  private modalRoot: HTMLElement | null
  private container: HTMLElement

  constructor(props: ModalProps) {
    super(props)

    this.handleClose = this.handleClose.bind(this)

    this.modalRoot = null
    this.container = document.createElement("div")
    this.container.classList.add("overlay")

    this.state = {
      isLoading: false,
      hasError: false
    }
  }

  handleClose = () => {
    // const modalRoot = document.getElementById("modal-root")

    if (typeof this.modalRoot !== null) {
      ReactDOM.unmountComponentAtNode(this.modalRoot)
    }
  }

  componentDidMount() {
    this.modalRoot = document.getElementById("modal-root")

    if (this.modalRoot !== null) {
      this.modalRoot.classList.add("scroll-bar")
      this.modalRoot.appendChild(this.container)
    }

    // Add classes to elements and attach onClick callback
    const { classes, handleClose } = this.props

    const closeButton = document.createElement("div")
    closeButton.appendChild(document.createTextNode("X"))
    closeButton.onclick = handleClose

    if (classes) {
      closeButton.classList.add(classes.closeButton)
    }

    this.container.appendChild(closeButton)
  }

  componentWillUnmount() {
    if (this.modalRoot !== null && this.container) {
      this.modalRoot.removeChild(this.container)
    }
  }

  componentDidCatch(error: any, info: any) {
    this.setState((prevState, props) => {
      while (this.container.firstChild) {
        this.container.removeChild(this.container.firstChild)
      }
      
      return {
        isLoading: false,
        hasError: true
      }
    })
  }
  
  render() {
    const { classes } = this.props

    if (classes) {
      this.container.classList.add(classes.overlay)
    }

    // Pew, Pew
    return ReactDOM.createPortal(
      this.props.children,
      this.container,
    )
  }
}

export default withStyles(styles)(Modal)
